.unauthorized-content {
  display: grid;
  justify-content: center;
  align-content: center;
  min-height: 95vh;
}

.unauthorized-content h2 {
  text-align: center;
  font-family: 'roboto';
}

.unauthorized-image {
  width: 60%;
  text-align: center;
  margin: auto;
}
